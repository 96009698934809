<template>
  <div>
    <ClassificaPersona :persona="persona" />
  </div>
</template>

<script>
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ClassificaPersona from "@/views/persone/dettaglio-persona/ClassificaPersona.vue";
import { ref } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "anagrafiche-insegnante",
  components: { ClassificaPersona },
  props: {
    persona: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const route = useRoute();
    const id_persona = ref(route.params.id_persona);

    onMounted(() => {
      setCurrentPageBreadcrumbs("Area Riservata");
    });

    return {
      id_persona,
    };
  },
});
</script>
